.ot {
  color: orange;
  font-family: "heading";
}

.order_h {
  font-size: medium;
  font-family: "delivery";
}
.card > h4 {
  font-size: medium;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.card:hover {
  border: 2px solid orange;
}

.top-secpad {
  padding: 30px;
  /* padding-top: 200px; */
}
.btn-order {
  background-color: orange;
  border: none;
  color: #ffff;
}
.orderline {
  border-bottom: dashed gray;
}
.order-stats__content  {
  width: 30px !important;
  max-width: 100px; /* Example: limit the width of the image */
  height: auto;
}
.duck-i{
  width: 30px;
  height: 20px;
}
.reorderbtn {
  background-color: orange;
  color: white;
  border: none;

  font-family: "add-btn";
}
.helpbtn {
  padding: 10px;
  color: orange;
  font-family: "add-btn";
  border: 1px solid orange;
  background-color: white;
  width: 90px;
}

.btn_align {
  display: flex;
  gap: 20px;
}
.name-alg{
  width: 60%;
}
.name-alg1{
  width: 46%;
}
.name-alg3{
  width: 62%;
}
.btn_head {
  display: flex;
  justify-content: space-between;
}
.link {
  color: skyblue;
  cursor: pointer;
  font-family: "branch-name";
}
.subtotal{
  display: flex;
  flex-direction: column;
}
.sub-price{
  display: flex;
  flex-direction: column;
 
}
.subtotal_1{
  display: flex;
  justify-content: space-between;

}
.subtotal-0{
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
    padding-right: 20px;

}
.sub-price_1{
  width: 25%;
}
.total-price{
 margin-top: 10px;
 color: #000;
 font-weight: 400;
}


.mb{
  color: #000;
}
.btn-confirm {
  border: none;
  background-color: #ff931e;
  color: #ffff;
  font-weight: bold;
  border-radius: 5px;
  padding: 5px;
  margin: 15px;
}
.btn-can {
  border: none;
  background-color: rgb(224, 19, 19);
  color: #ffff;
  font-weight: bold;
  border-radius: 5px;
  padding: 5px;
  margin: 15px;
}
s

/* .modal-history{
  display: flex;
  justify-content: end;
}

.modal-fixed-right {
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 40%; 
  
}
.txt-font{
font-size: 14px;
}

.modal-fixed-right .modal-content {
  padding: 0;
  border-radius: 0;
}
.status-container {
  display: flex;
  justify-content: space-between;

  
}

 .status{
  height: 100vh;
}

.status-item p {
  margin: 0;
} */ 


/* 
.fixed_right {
  height: 50vh;
  overflow: auto;
} */

.icon-order {
  color: #ffbe78;
 
}
.icon-order-dark {
  color: #ff931e;
  
  
}
.status-wrapper {
  display: flex;
  justify-content: space-around;
}

.step-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.step-item {
  position: relative;
  text-align: center;
}

.step-item .icon-order,
.step-item .icon-order-dark {
  font-size: 1.5rem;
}


  .step-item .stepper {
    position: absolute;
    top: 41%;
    left: calc(243% - 25px);
    width: 161px;
    height: 1px;
    border: 1px dashed black;
    z-index: 1;
}

.stepper-active{
  position: absolute;
  top: 41%;
  left: calc(243% - 25px);
  width: 161px;
  height: 1px;
  border: 1px dashed orange;
  z-index: 1
}
.step-item:first-child::before {
  display: none; /* Hide the line before the first icon */
}


.step-label {
  margin-top: 8px; /* Adjust spacing between icon and label */
}



.modal-fixed-right .btn-close {
  margin-left: 230px;
}

.ot {
  color: orange;
  font-weight: bold;
}
.case {
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  padding: 20px;
  gap: 10px;
}
.order_h {
  font-size: medium;
}
.case > h4 {
  font-size: medium;
}

.case:hover {
  border: 2px solid orange;
}
.order_main {
  overflow: scroll;
  height: 100vh;
  padding-bottom: 150px;
}
.top-secpad {
  padding: 30px;
  /* padding-top: 200px; */
}
.orderline {
  border-bottom: 1px dashed gray;
}

.helpbtn {
  padding: 10px;
  color: orange;
  font-weight: bold;
  border: 1px solid orange;
  background-color: white;
  width: 90px;
}

.btn_head {
  display: flex;
  justify-content: space-between;
}
.orderhead {
  width: 92%;
  height: 100%;
  padding-top: 10px;
  z-index: 1;
  background-color: rgba(200, 200, 200, 0.7);
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
}
.sub-row{
  display: flex;
  justify-content: space-between;
 margin-left: -12px;
}
.ordercontain {
  width: 35%;
  height: 89%;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border-radius: 20px;
  border-color: #000;
  font-size: 25px;
  cursor: pointer;
  color: #000;
  margin-right: 7px;
  margin-top: 10px;
}

.modelContainer .title {
  text-align: center;
  margin-top: 10px;
}

.modelContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modelContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modelContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
.custom-modal-body {
  
 padding: 0px;
}
.custom-modal-title-container{
  margin-left: 40%;
}
.td-item{
  width: 51%;

}
.td-item-1{
 width: 45%;
}
.td-item-2{
  width: 0;
}
.sub-price{
  margin-left: -36px;
}
@media (max-width: 1400px) {
  .orderhead {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ordercontain {
    width: 55%;
  }
}

@media screen and (max-width: 640px) {
  .order_main {
    margin-top: 30px;
    margin-left: 0px;
   
  }
  .branch_card_page > h4 {
    font-size: small;
    display: flex;
    flex-direction: column;
  }
  .order_h {
    font-size: small;
  }
  .btn_align > button {
    font-size: 10px;
    width: 70px;
    height: 30px;
  }
  .btn_head {
    display: flex;
    flex-direction: column;
  }
  .order_h {
    margin-top: 10px;
  }
  .btn_align {
    display: flex;
  }
  .ot {
    font-size: medium;
  }
  .reorderbtn {
    padding: 5px;
  }
  .helpbtn {
    padding: 5px;
  }
  .td-item{
  font-size: small;
  }
  
  .td-item-1{
    font-size: small;
  }
  .td-item-2{
    font-size: small;
  }
  .subtotal{
    font-size: small;
  }
  .sub-price{
    font-size: small;
  }
  .status-wrapper{
    font-size: small;
  }
}
@media screen and (max-height:400px){
  .subtotal_1{
    width: 70%;
  }
  .sub-price_1{
    width: 30%;
  }
 
}

@media (max-width:990px){
  .stepper-active{
    width: 82px;
  }
 .step-item .stepper{
    width:82px;
  }
  .sub-price{
    margin-left: -25px;
  }
  .td-item{
    width: 47%;
  }
  .td-item-1{
    width: 45%;
  }
}
@media (max-width:640px){
  .td-item{
    width: 49%;
  }
}
@media (max-width:500px){
  .stepper-active{
    width: 66px;
  }
  .step-item .stepper{
    width: 66px;
  }
}
@media (max-width:413px){
  .stepper-active{
    width: 50px;
  }
  .custom-modal-title-container{
    margin-left: 2%;
  }
  .step-item .stepper{
    width: 50px;
  }
}
@media (max-width:357px){
  .stepper-active{
    width: 35px;
  }
  .step-item .stepper{
    width: 35px;
  }
}