.line{
    border-bottom: 1px solid;
}
.line-doted{
    border-bottom: dashed;
    color: #ff8603;
}


.card p {
  font-size: 16px;
  color: #555;
}




.table_align{
  display: flex;
  justify-content: space-between;
}







.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
