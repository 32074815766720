/* YourComponent.css */
.con-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shape {
  border-radius: 12% 88% 73% 27% / 30% 30% 70% 70%;
}

.btn-call {
  border: none;
  background-color: #ff9728;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  color: #474747;
  font-weight: bold;
  font-family: 'add-btn';
}

.at-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-para {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.at-item {
  font-weight: bold;
  font-size: 3em;
  animation-name: focus-in-contract;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}
.at-item h1 {
  color: #ff8603;
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {

  .at-container {

    padding: 20px;
  }
  .btn-call {

    margin-bottom: 10px;
  }
  .mobile-view{
    margin-top: 200px;
  }
}