.add_card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin: 15px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.fixed-heading {
 overflow: scroll;
 height: 100vh;
 padding-bottom: 100px;
}

.heading {
  color: #ff8603;
  text-align: center;
  padding: 10px;
  font-family:'delivery';
}
.span-txt{
  color: #ff8603;
  font-size: x-large;
}

.bold {
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.orange-sel{
  background-color: #fca445;
}
.add_card-values h6 {
  color: #a7a5a5;
  margin: 5px 0;
  font-family: "description";
}
.img-addtocart {
  width: 400px;
}

.btn-address{
  padding: 10px 20px;
  background-color: #f90;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.right-align{
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}
.btn-right {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.addd_head{
    display: flex;
    justify-content: end;
    padding: 2px;
}
.btn-edt_69 {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: #228B22; 
  border-radius: 5px;
  outline: none;
}

.btn-edt_69.orange {
  background-color: #f90; 
}

.btn-edt_69.black {
  background-color: #228B22; 
}

.btn-edt_69.black:hover {
  background-color: #1e7e1e; 
}

.btn-edt,
.btn-del {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: #f90; 
  border-radius: 5px;
  outline: none;
}

.btn-del {
  background-color: #d9534f; 
}
.select{
  color: orange;
  border: 2px solid orange;
}

@media (max-width: 768px) {
  .container {
    justify-content: flex-start;
  }
  .add_card {
    margin: 15px 10px;
  }
}


@media screen and (max-width: 640px){
  .fixed-heading{
    margin-top: 30px;
    margin-left: 0px;
    
  }
  
}
@media (max-width:300px){
  .btn-edt_69{
    margin:1px;
  }
  .addd_head {
    margin: -21px;
    margin-top: 2px;
}
}
