.h1{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    
}

.para{
   font-size: 20px;
}
.policy_h{
    overflow: auto;
    height: 100vh;
    width: 89%;
   display: flex;
   flex-direction: column;
   gap: 20px;
   background-color: whitesmoke;
   padding-left: 30px;
}