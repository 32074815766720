.img-cart img {
  width: 100px;
  cursor: pointer;
}



.card_11 p {
  margin: 0;
}
.input-group {
  border-radius: 8px;
}
.btn-apply {
  border-radius: 0px 8px 8px 0px;
  border: 0;
}
.add_btn1 {
  margin-bottom: 10px;
}

.card_21 {
  display: flex;
  flex-direction: row;

  margin-bottom: 10px;
}
.menu-container {
  display: flex;
  align-items: center;
}

.image-container {
  margin-right: 20px;
}

.text-container {
  flex: 1;
 
}

.menu-name {
  color: #333;
  font-size: 20px;
}

.menu-description1 {
  color: #666;
}

.menu-price1 {
  font-weight: bold;
}

.cartt_image1 {
  height: 200px;
}

.quantity-container1 {
  display: flex;
  align-items: center;
}

.quantity-container1 button {
  background-color: #ff8603;
  color: white;
  border: none;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.page-wrapper1 {
  text-align: right;
}

.add_btn1.sendtocart1 {
  background-color: #ff8603;
  color: #fff;
}

.category_name1 {
  height: 300px;
  background: rgb(255, 134, 3);
  background: linear-gradient(
    90deg,
    rgba(255, 134, 3, 1) 0%,
    rgba(228, 119, 0, 1) 95%,
    rgba(230, 136, 35, 1) 100%
  );
  padding: 30px;
  overflow-y: auto;
  border-radius: 10px;
}

.category_name1::-webkit-scrollbar {
  width: 0;
  display: none;
}

.summary_heading {
  color: #ffff;
  font-size: 20px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  z-index: -1000px;
}
.summary_menu_name {
  color: #fff;
}
.summary_fullname {
  display: flex;
  justify-content: space-between;
}
.summary_qty {
  color: white;
}

.card-content {
  display: flex;
  align-items: center;
}

.image-container {
  margin-right: 10px;
}

.text-content {
  flex-grow: 1;

}

.price-and-quantity {
  display: flex;
  align-items: center;
}

.pay_card {
  padding: 50px;
  background-color: #ddd;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border: none;
  position: relative;
  border-radius: 10px;
  width: 100%;
}
.login_allow {
  display: flex;
  justify-content: end;
  margin-top: 40px;
}
.prcd_login {
  color: white;
  border: 1px solid #ff8603;
  padding: 10px;
  background-color: #ff8603;
  width: 200px;
  font-size: small;
  padding: 4px;
}
.btn_split {
  display: flex;
  gap: 20px;
}
.prcd_login1 {
  color: #ff8603;
  border: 1px solid #ff8603;
  background-color: white;
  width: 200px;
  padding: 4px;
  height: 59px;
  font-size: small;
}

.delivery_ad {
  border: 1px solid whitesmoke;
  padding: 30px;
  background-color: white;
  color: gray;
  margin-top: 20px;
}
.ac {
  font-weight: bold;
}
.mob_number {
  padding: 20px;
  margin-top: 10px;
  border: gray;
}
.LOGIN {
  background-color: #ff8603;
  color: white;
  padding: 10px;
  border: none;
  margin-top: 10px;
}
.inpt_lg {
  display: flex;
  flex-direction: column;
}
.Signup_pop {
  padding: 20px;
  margin-top: 5px;
  border: gray;
}
.Signup_lg {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.steper {
  display: flex;
  flex-direction: column;

  position: absolute;
  left: 3%;
}
.icon_1 {
  width: 30px;
  height: 35px;
}
.dashed_ln {
  border-left: dashed gray;
  height: 110px;
  margin-left: 12px;
}
.dashed_ln2 {
  border-left: dashed gray;
  height: 250px;
  margin-left: 12px;
}
.inputtt {
  display: flex;
  gap: 10px;
}
.tick {
  color: #ff8603;
  width: 50px;
  height: 50px;
}
.add_address {
  padding: 10px;
  background-color: #ff8603;
  color: white;
  border: none;
}
.ad_address {
  margin-top: 70px;
}
.paymeth {
  padding: 10px;
  color: white;
  background-color: #ff8603;
  border: none;
}
.paymeth_1 {
  margin-top: 70px;
}
.pay_mod {
  padding: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  border: none;
  margin-top: 18px;
}
.cas_btn {
  padding: 10px;
  border: none;
  color: #ff8603;
  background-color: transparent;
}
.cash_free {
  width: 90px;
  height: 30px;
}
.razor_pay {
  width: 90px;
  height: 30px;
}
.upi_pay {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  margin-top: 10px;
}
.cash_free_btn {
  background-color: white;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  padding: 10px;
  border: none;
}
.address_card {
  padding: 40px;
  width: 50px;
}
.case_1 {
  padding: 20px;
  gap: 10px;
}
.Addresss {
  padding: 25px;
  border: 1px solid gray;
  width: 400px;
  margin-top: 5px;
}
.home_work {
  display: flex;
  justify-content: space-around;
}
.home_work > p {
  border: 1px solid;
  padding: 10px;
  border: 1px solid gray;
}
.home-work-other {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}
.save_address {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.save_address_1 {
  padding: 20px;
  color: white;
  background-color: ff8603;
  border: none;
  background-color: #ff8603;
  max-width: 100%;
}


.form-check-label {
  color: #ffffff;
}
.card-sh {
  box-shadow: 0 5px 10px 0 #ddd;
  border-bottom: 1px solid #ddd;
  width: 80%;
}

.img-addtocart {
  width: 500px;
}

.order_align {
  margin-top: 40px;
}
.os-out-div {
  position: sticky;
  background: linear-gradient(    90deg,    rgba(255, 134, 3, 1) 0%,    rgba(228, 119, 0, 1) 95%,    rgba(230, 136, 35, 1) 115%  );
  z-index: 99;
  right: 0;
  left: 0;
  top: 0;
  padding: 10px;
  padding-top: 15px;
}
.os-under-div {
  padding-top: 0px;
  z-index: 0;
  position: relative;
}
