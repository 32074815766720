.card_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40px;
  /* padding-left: 30px; */
  gap: 10px;
}

/* Assuming you have a parent container with a class of 'list_item' */
.list_item {
  list-style: none;
  /* Remove default list styles */
  padding: 0;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.address-select-message {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  /* Adjust as needed */
  animation: blink 1s infinite;
}

.list_item li input[type="radio"] {
  margin-right: 0.5rem;
}

.list_item li label {
  cursor: pointer;
}

.topics {
  display: flex;

  justify-content: center;
}

.lin-or::before {
  border-top: 1.5px solid #f89406;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  z-index: -1;
}

.loginame {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-weight: 600;
  color: #f89406;
}

.lin-or span {
  background-color: #fff;
  padding: 0px 10px;
}
/* Style the radio button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid gray; /* Orange border */
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}
.online-p{
  border: none;
  background-color: #f89800;
  color: white;
  padding: 10px;
 border-radius: 3px;
 font-weight: 400;
}
.two-buttons{
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
/* Style for the checked radio button */
input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff9800; /* Orange background */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.select-t {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensures button is positioned relative to this container */

}

.header-t {
  position: absolute; /* Positions the button absolutely within the select-t container */
  top: 10px;
  right: 10px;
}



.message-t {
  margin-top: 30px; /* Adds some space below the button */
  text-align: center;
}

.button-t{
  border: none;
  background-color: white;
  font-size: 26px;
  cursor: pointer;
}
.lin-or {
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.search-box {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.search-input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.modal_content_11 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 100%;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  /* Prevent images from wrapping to the next line */
}

.search-input {
  width: 100%;
  /* Adjust width as needed */
  padding: 10px;
  /* Adjust padding as needed */
  font-size: 16px;
  /* Adjust font size as needed */
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.search-input:focus {
  outline: none;
  border-color: #ddd;
}

.icon {
  cursor: pointer;
  font-size: 25px;
}

.search-input::placeholder {
  font-size: 18px;
}

.card_2 {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .search-input::placeholder {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .search-input::placeholder {
    font-size: 14px;
  }
}

.search-icon {
  position: absolute;
  color: #474747;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.today_spl {
  display: flex;

  justify-content: center;
}

.quantity-container {
  display: flex;
  align-items: center;
}

.quantity-container .add_btn {
  background-color: #ff8603;
  color: white;
  border: none;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  height: 35px;
  width: 70px;
  font-family: "add-btn";
}

.profile_icon {
  width: 30px;
  height: 30px;
  margin: 5px;
  cursor: pointer;
}

.custom-modal .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.darksoul-dropdown {
  position: relative;
 
}

.darksoul-dropdown-content {
  display: none;
  list-style: none;
  cursor: pointer;
}

.darksoul-dropdown-content>p:hover {
  background-color: #ffffff;
  color: #f89406;
}

.darksoul-dropdown:hover .darksoul-dropdown-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  height: fit-content;
  width: fit-content;
  list-style: none;
  box-shadow: 0px 0px 10px rgb(168, 167, 167);
  border-radius: 5px;
  margin-top: 0px;
  position: absolute;
}

.quantity-container h6 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}

.home_head61 {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  padding: 5px;
  z-index: 1;
}

.mh-head-1 {
  position: fixed;
}
.coupon-change {
  border-radius:5px 0px 0px 5px;
  border-color: #cac9c9;
  border-width: 1px;
  border-style: double;

}
.home_headright {
  display: flex;
  justify-content: flex-end;
}
.lottie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

.lottie-overlay .lottie-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_headright_1_content {
  display: flex;
  align-items: center;
}

.home_headright_1 {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.add_btn {
  color: #ffff;
  cursor: pointer;
  border: none;
  background-color: #f48407;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #f89406;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

.mh_head_1 {
  position: fixed;
}

.sold_btn {}

.next-available {
  background-color: #fff;
  color: #928f8f;
  font-size: 10px;
}

.menu-name_m {
  font-size: 16px;
  margin: 0;
  text-align: left;

  font-weight: bold;
}

.menu-description {
  font-size: small;
}

.cartt_image {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.sendtocart {
  animation: shake 0.5s;
}

.cutting_61 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bold{
  font-weight: 200;
}
.quantity-btn {
  width: 30px;
  height: 100%;
  font-weight: bold;
  font-size: 16px;  
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.btn-price {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-price {
  margin: 0;
  font-family: "delivery";
}

.price-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cshadow_61 {
  position: relative;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(236, 235, 235, 0.2);
  padding: 8px 80px;
  border-radius: 8px;
}

.cutting_content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  border: none;
}

.cutting {
  font-weight: 600;
}

.cart {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cart img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.slider {
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.cart-icon-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-weight: bold;
}

.cart-icon {
  color: #f89406;
}

.btn-order {
  background-color: #ff8603;
  border: none;
  font-weight: 500;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  font-family: "delivery";
}

.cart {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 5px;
}

.cart-items-count {
  color: #ffffff;
  font-weight: bold;
  padding: 5px;
  font-size: 12px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8603;
}

.card_2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  justify-content: space-between;
  flex-direction: row;
}

.card_3 {
  width: 350px;
  height: 350px;
}

.cart_4 {
  height: 50%;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.description {
  color: #474747;
  margin: 5px 0 15px 0;
  font-size: 12px;
  text-align: left;
  font-family: "description";
}

.login_color {
  color: #f89406;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.bo_bot {
  border-bottom: orange 1px solid;
  cursor: pointer;
}

.card_2:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(1, 1, 1, 0.5);
}

.navigate {
  font-size: 12px;
}

.input_des_69 {
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 372px;
}

.input_des_699 {
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border: none;
  padding: 10px;
  border-radius: 2px;
  width: 382px;
  border-left: none;
}

.l_and_s {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.sp_91 {
  background-color: #dee2e6;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border: none;
  padding: 13px;
  border-radius: -10px;
  border-right: none;
}

.bill_details {
  display: flex;
  flex-direction: column;
  font-family: "delivery";
  border: 1px gray dashed;

  font-size: small;
  margin-bottom: 52px;
  margin-top: 20px;
  font-family: Lato Regular;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}

.bill_details>h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: "heading";
}

.dhc {
  display: flex;
  justify-content: center;
}

.dhsp {
  margin-left: 3px;
  color: orange;
  cursor: pointer;
  border-bottom: orange 1px solid;
}

.item_total {
  display: flex;
  justify-content: space-between;
  margin: -4px;
}

.item_total_1 {
  display: flex;
  justify-content: space-between;
}

.item_total_1>h6 {
  font-family: "heading";
}

.item_total>h3 {
  font-size: small;
  font-weight: bold;
}

.image-container {
  margin-right: 10px;
  width: 95%;
}

.item_total_69>h3 {
  font-size: small;
  font-weight: bold;
}

.item_total>p {
  color: gray;
  font-family: "description";
  margin: 5px;
  display: flex;
  justify-content: flex-end;
}

.mod_lg_sg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.signup_align_69 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.log_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.log_top_69 {
  display: flex;
  align-items: center;
}

/* Date Picker Styles */
#datepicker {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Time Picker Styles */
#timepicker {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .cart_items {
    display: flex;
    align-items: center;
  }
}

.home_headleft {
  width: 100px;
  display: flex;
  align-items: center;
}

.home_headleft img {
  width: 100%;
  cursor: pointer;
}

.btn-sytle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-sub {
  border: none;
  background-color: orange;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px;
}

.cart img.clicked {
  transform: translateX(20px);
}

.Carousel_Header {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
/* .carousel-control-next-icon {
  margin-left: 100px;
} */

@media (max-width: 768px) {
  .carousel-control-prev-icon {
  
  }

  .carousel-control-next-icon {
    margin-left: 10px;
  }
}

.img-veg {
  width: 10px;
}

.food-image {
  display: flex;
}

@media (max-width: 576px) {
  .lin-or::before {
    width: 100%;
    top: 50%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .lin-or::before {
    width: 50%;
    top: 46%;
  }
}

@media (min-width: 769px) {
  .lin-or::before {
    width: 50%;
    top: 48%;
  }
}

.carousel-inner img {
  object-fit: cover;
}

/* @media (min-width: 768px) {
  .carousel-inner img {
    max-height: 400px; 
    object-fit: cover; 
  }
} */

@media (max-width: 767px) {
  .carousel-inner img {
    max-height: 250px;
  }
}

.add_btn_out h6 {
  width: 22px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #f89406;
  background-color: #fff;
  font-size: 14px;
}

.btnlogin_69 {
  border: none;
  background-color: #f48407;
  color: #ffffff;
  padding: 5px;
  width: 380px;
  border-radius: 5px;
}

.img_fluid_69 {
  max-width: 40%;

  height: auto;
  margin-left: 30%;
}

.sp_91 {}

.bdetails {
  border-bottom: 1px gray dotted;
  font-size: small;
  font-weight: bold;
  color: orange;
}

.item_total_69 {
  display: flex;
  justify-content: space-between;

  margin-top: 5px;
  margin-bottom: -24px;
}

.item_total_59 {
  display: flex;
  justify-content: space-between;
  margin: -4px;
}

.item2total {
  display: flex;
  /* margin: -4px; */
}

.item_total_59>p {
  font-size: small;
  color: gray;
}

.b_detail69 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dot_only {
  border: 1px gray dashed;
}

.bdetails_total {
  color: orange;
}

.total_amount {
  color: orange;
}

.Add_1 {
  background-color: orange;
  color: white;
  padding: 7px;
  border: none;
  width: 89px;
  border-radius: 5px;
}

.add_direction {
  display: flex;
  justify-content: center;
}

.list_item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style-type: none;
}

.btn-apply {
  background-color: #f48407;
  color: #fff;
}

.gap {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
}

.header-topics {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: grey;
}

.will-choose {
  color: white;
  padding: 4px;
  /* border-color: #f48407; */
  background-color: orange;
  border: none;
  font-weight: bold;
  width: 50%;
  max-width: 128px;
  height: 40px;
  margin: 5px;
}

.repeat {
  color: #ffffff;
  background-color: orange;
  width: 50%;
  font-weight: bold;
  height: 40px;
  max-width: 128px;
  padding: 4px;
  border: none;
  /* margin: 5px; */
}

.addons_card {
  background-color: white;
  padding: 4px 10px 0 10px;
  margin-top: 5px;
 border-bottom: 1px dotted rgb(165, 162, 162);
  margin-bottom: 5px;
}

.customize {
  color: #417505;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 0;
}

.sold {
  border: 0;
  background: #928f8f;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 700;
}

.btn-sold {
  background-color: #fffdfd;
  border: 0;
}

.repeat-modal-body {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
}

.two-btn {
  display: flex;
  align-items: center;
  gap: 16px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
.cart-boxes-shadow{
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  padding: 10px;
}
.cont-shop {
  padding: 5px 10px;
  margin-right: 10px;
  border: none;
  background-color: #f89406;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.cont-shop:hover {
  background-color: #fdb248;
}

.order-link {
  color: #000000;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  margin-right: 40px;
  cursor: pointer;
}

.order-link:hover {
  text-decoration: underline;
}

.order-link i {
  margin-left: 5px;
}

.blink-animation {
  animation: blink 1s infinite;
}

.next-available {
  color: #f48407;
  font-size: 12px;
}

.addons_card2 {
  display: flex;
  gap: 5px;
}

.addons_card3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addons_flex {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.index_1 {
  background-color: whitesmoke;
  padding-left: 5px;
  padding-right: 5px;
}

.add_btn_out {
  height: 28px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff8603;
  border-radius: 5px;
  box-shadow: 2px 4px 5px 1px #e7e7e7;
}

.rounded-container {
  border-radius: 100%;
  margin: 5px;
}

.variant-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.variant-item input {
  margin-right: 5px;
}

.rounded-image {
  width: 150px;
  height: 150px;
  border-radius: 100px;
}

.btn-change {
  border: none;
  background-color: transparent;
  color: #f48407;
  font-family: "heading";
  display: flex;
  justify-content: flex-end;
}

.clr {
  color: #474747;
}

.login_continue {
  display: flex;
  justify-content: center;
  height: 190px;
  position: sticky;
  width: 100%;
  background: white;
  bottom: 0;
}

.form-control {
  border-radius: 0;
}

.cart-icon-container {
  position: relative;
  display: inline-block;
  /* Ensures the container takes the size of its content */
}

.cart-items-count {
  position: absolute;
  top: -5px;
  /* Adjust the vertical position as needed */
  right: -5px;
  /* Adjust the horizontal position as needed */
  background-color: #f89406;
  /* Change the background color as needed */
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}

/* Modal - CSS */

.modal-right {
  transform: translate3d(25%, 0, 0);
  -webkit-transform: translate3d(25%, 0, 0);
  -ms-transform: translate3d(25%, 0, 0);
  right: 0;
  top: 0;
  bottom: 0;
  margin-right: 0;
}

/* modal-cart */
.modelBackground {
  width: 101vw;
  height: 113vh;
  z-index: 2;
  background-color: rgba(200, 200, 200, 0.7);
  position: fixed;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;

  box-sizing: border-box;
}

.modelContainer {
  width: 500px;
  height: 100vh;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-sizing: border-box;
  overflow: auto;
  /* position: absolute; */
}

.delivery-date {
  display: flex;
  flex-direction: column;
}

.input-data-time {
  display: flex;
  gap: 100px;
}
.input-data-time label {
  font-weight: bold;
}
.title {
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
}
.input-data-time input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.modelContainer .title {
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  background-color: transparent;
  border-radius: 50px;
  /* border-color: #000; */
  font-size: 20px;
  cursor: pointer;
  color: gray;
  margin: 0px 10px 10px 10px;
  padding-top: 3px;
  border: none;
  font-weight: 600;

}
.titleCloseBtn:hover{
  color: #ddd;
}

.modelContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modelContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modelContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.text-clr {
  color: #f48407;
  font-weight: bold;
}

#cancelBtn {
  background-color: crimson;
}

.log_to_cont {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px;
  font-family: "add-btn";
  border-radius: 5px;
  width: 100%;
}

.img-veg-addons {
  height: 15px;
  margin: 5px;
  width: 15px;
}

.cod {
  width: 100%;
  height: 80%;
  cursor: pointer;
}

.addons_card12 {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  margin-top: 5px;
  box-shadow: 0px 0px 10px rgb(168, 167, 167);
}

.razorpay {
  cursor: pointer;
}

.addons_card11 {
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  margin: 2px;
  box-shadow: 0px 0px 10px rgb(199, 194, 194);
}

.search-results-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 90%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.search-results-dropdown ul {
  list-style: none;
  /* Remove default list styles */
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
 
  
}

.search-results-dropdown ul li {
  padding: 8px;
  cursor: pointer;
}

.search-results-dropdown ul li:hover {
  background-color: #f0f0f0;
}

.search-results-dropdown li.selected {
  background-color: #f0f0f0;
}

.res-top-disp {
  display: block;
}

.res-btm-disp {
  display: none;
}
.cus-1{
  display: flex;
  justify-content: space-between;
  margin-right: -4px;
}
.cus-2{
  font-size: 14px;
}
@media (max-width:400px){
  .two-buttons{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .online-p{
    width: 161px ;
  }
}
@media (max-width: 576px) {
  .lin-or::before {
    width: 100%;
    top: 50%;
  }

  .input-data-time {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .btn-sold {
    width: 99px;
    position: absolute;
    right: -15px;
    bottom: 5px;
    border-radius: 5px;
  }

  .sold {
    position: absolute;
    right: 16px;
    bottom: 0;
  }

  .next-available {
    font-size: 11px;
  }
  .quantity-btn{
    height: 100%;
  }
  .add_btn_out{
    height: 35px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .lin-or::before {
    width: 50%;
    top: 46%;
  }
}

@media (min-width: 769px) {
  .lin-or::before {
    width: 50%;
    top: 48%;
  }
}

@media (max-width: 767px) {
  .carousel-inner img {
    max-height: 150px;
  }

  .gap {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .carousel-control-prev-icon {
    left: -28px;
  }

  .carousel-control-next-icon {
    left: 110%;
  }

  .rounded-image {
    width: 70px;
    height: 70px;
    border-radius: 100px;
  }
}

@media (max-width: 450px) {
  .home_head61 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-left: 20px; */
  }

  .rounded-image {
    width: 73px;
    height: 73px;
    border-radius: 100px;
  }

  .gap {
    gap: 5px;
  }
}

@media (max-width: 470px) {

  .modelContainer {
   height: 85vh;
  }

  .search-input-container {
    margin-right: 0%;
    width: 100%;
  }
  .logen_stricky{
    margin-bottom: 50px;
  }
}

@media (min-width: 280px) and (max-width: 420px) {
  .rounded-image {
    width: 67px;
    height: 67px;
  }
  .form-check{
    padding-left: 0 !important;
  }
  .form-date-time{
    font-size: 13px;
  }
  .header-topics>p {
    font-size: 10px;
  }

  .input_des_699 {
    width: 300px;
  }

  .input_des_69 {
    width: 260px;
  }

  .btnlogin_69 {
    width: 262px;
  }

  .search-input-container {
    margin-right: 0%;
    width: 94%;
  }
}

@media (min-width: 820px) and (max-width: 1024px) {
  .rounded-image {
    width: 80px;
    height: 80px;
  }
}

.razorpay {
  width: 100px;
}

@media (max-width: 400px) {
  .btnlogin_69 {
    width: 250px;
  }

  .search-input-container {
    margin-right: 0%;
    width: 100%;
  }

  .razorpay {
    width: 50px;
  }
}

@media (max-width: 370px) {
  .input_des_699 {
    width: 230px;
  }

  .search-input-container {
    margin-right: 0%;
    width: 100%;
  }

  .razorpay {
    width: 50px;
  }

  .cod {
    width: 100%;
    height: 80%;
  }
}
@media (max-width:300px){
  .btnlogin_69 {
    width: 260px;
  }
  .input_des_699 {
    width: 213px;
  }
}
@media (max-width: 500px) {
  .razorpay {
    width: 50px;
  }

  .cod {
    width: 50px;
  }

  .payment_3 {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 320px) {
  .razorpay {
    width: 40px;
  }

  .cod {
    width: 40px;
  }
}

@media(max-width:600px) {
  .home_head61 {
    flex-direction: row;
    position: fixed;
    padding-bottom: 60px;
    z-index:2;
  }
  .Carousel_Header {
    margin-top: 150px;
  }

  .img-cart img {
    width: 65px !important;
  }

  .search-input-container {
    position: absolute;
    bottom: 10px;
  }
}


@media(max-width:600px) {
  .resp-catout {
    box-shadow: 0px 0px 10px 0px #e7e7e7;
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .resp-out-img {
    width: 110px;
    height: 110px;
  }

  .resp-out-img img {
    height: 100%;
    width: 100%;
  }

  .resp-quan-cont {
    position: absolute;
    bottom: 0;
    right: 20px;
  }

  .resp-right-hei {
    height: 130px;
  }

  .resp-btn-out-plus {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .res-top-disp {
    display: none;
  }

  .res-btm-disp {
    display: block;
  }
}