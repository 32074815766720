body {
  line-height: 24px;
}
.out-sect {
  padding: 40px 0px;
  /* display: flex; */
}
.sl-txt {
  font-size: 30px;
  line-height: 42px;
  display: flex;
  position: relative;
}
.o-bx {
  position: absolute;
  left: 174px;
  top: -17px;
  background-color: #ff8603;
  width: 40px;
  height: 45px;
  border-radius: 3px;
  transform: skew(175deg);
  rotate: -6deg;
}
.lis-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  /* padding: 10px; */
}
.lis-grid-item {
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  /* padding: 20px 0px; */
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.font-km{
  font-size: 18px;
  width: 45%;
}
.fd-img {
  width: 220px;
  height: 220px;
}
.fd-img img {
  width: 100%;
  /* height: 100%; */
}
.branch-details .loc-top {
  font-size: 23px;
  margin: 0;
  padding: 10px 0px 20px 0px;
  font-weight: bold;
  text-align: left;
}

.card-text {
  font-size: 14px;
  font-weight: bold;
  color: #474747;
  margin: 0;
}
.text-start1{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dis-fon {
  font-size: 17px;
  line-height: 16px;
  background-color: #6610f2;
  color: white;
  padding: 5px;
  width: 177px;
  border-radius: 12px;
}


.branch-details {
  width: 220px;
  padding: 10px;
}
img[alt] {
  font-size: 12px;
}

.otp-input {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.otp-box {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  margin-right: 10px;
}

.otp-box:last-child {
  margin-right: 0;
}
.Select-shop{
  text-align: center;
  font-weight: bold;
  font-size: 30pxpx;
}

.lin-or::before {
  border-top: 1.5px solid #f89406;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  z-index: -1;
}
.lin-or span {
  background-color: #fff;
  padding: 0px 10px;
}
.lin-or {
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.svg-container {
  overflow: hidden;
  max-width: 80px; 
}
.straight-line {
  stroke-dasharray: none; 
}
.d-flex {
  display: flex;
}

.map-marker-icon {
  font-size: 20px; 
  color: #474747; 
}


.align-items-center {
  align-items: center;
}

/* Fonts */
@font-face {
  font-family: 'heading';
  src: url('../../Assests/Fonts/Inter-Medium.otf'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'delivery';
  src: url('../../Assests/Fonts/Inter-Regular.otf'); 
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'branch-name';
  src: url('../../Assests/Fonts/Inter-Bold.otf'); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'description';
  src: url('../../Assests/Fonts/Inter-ExtraLight.otf'); 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'add-btn';
  src: url('../../Assests/Fonts/Inter-Black.otf'); 
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 500px) {
  .loc-top-sec {
    margin: 10px 0px;
  }
 
}
@media (max-width: 991px) {
  .lis-grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .home_headleft{
    margin-left: auto;
    margin-right: auto;
  }
  img {
    
  }
  .branch-details .loc-top {
   
    padding: 0px;
   
}
.dis-fon{
  font-size: 14px;
}
}
@media (max-width: 500px) {
  .fd-img {
    width: 160px;
    height: 170px;
    padding: 10px;
  }
  .home_headleft{
    margin-left: auto;
    margin-right: auto;
  }

  .branch-details .loc-top {
   
    padding: 0px;
   
}
}

@media (max-width: 400px) {
  .fd-img {
    width: 100%;
    height: 100%;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    padding: 10px;
}
  .branch-details .loc-top {
    padding: 0;
    font-size: 18px;
  }
  .branch-details img {
    width: 18px;
    height: 18px;
  }
  .card-text {
    font-size: 12px;
  }
  .branch-details {
    width: 56%;
  }
  .dis-fon {
    font-size: 13px;
  }
  .home_headleft{
    margin-left: auto;
    margin-right: auto;
  }
  .branch-details .loc-top {
   
    padding: 0px;
  
}
.font-km{
  font-size: 12px;
}
}




@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 576px) {
  .lin-or::before {
    width: 100%;
    top: 50%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .lin-or::before {
    width: 50%;
    top: 46%;
  }
  .home_headleft{
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 769px) {
  .lin-or::before {
    width: 50%;
    top: 48%;
  }
  .home_headleft{
    margin-left: auto;
    margin-right: auto;
  }
}
