.f1 {
  color: white;
}

.Footer_head {
  background-color: black;
  display: flex;
  justify-content: space-between;
  padding: 60px;
}
.loc {
  width: 20px;
  height: 20px;
  display: flex;
}
.locii {
  width: 17px;
  display: flex;
  align-items: center;
}
.ta {
  color: gray;
  font-size: medium;
  display: flex;
  justify-content: center;
}
.footer_2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer_3 > p {
  color: gray;
  font-size: medium;
}
.footer_4 > p {
}
.head_1 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.head2 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer_3 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer_4 span:hover {
  color: #f48407;
  transform: scale(1.08);
  cursor: pointer;
}
.tc {
  color: gray;
  font-size: medium;
}
.new_align {
  color: white;
}
.footer_3 > h4 {
  color: gray;
  font-size: small;
}

.footer_4 > a {
  text-decoration: none;
}
.footer_4 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.f2 {
  color: white;
}
.logo {
  width: 90px;
  height: 75px;
}

.copyright > p {
  color: white;

  padding-bottom: 30px;
  font-size: small;
  background-color: black;
}
.fooot {
  background-color: black;
}
.copyright > p {
  display: flex;

  justify-content: center;
  margin: 0%;
}

@media screen and (max-width: 390px) {
  .Footer_head {
    flex-direction: column;
    align-items: center;
  }

  .footer_1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_2,
  .footer_3,
  .footer_4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .head_1,
  .head2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .loc {
    width: 15px;
    height: 15px;
  }

  .footer_2 > h2 {
    margin-left: 0;
  }
}
@media screen and (max-width: 414px) {
  .Footer_head {
    flex-direction: column;
    align-items: center;
  }

  .footer_1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_2,
  .footer_3,
  .footer_4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .head_1,
  .head2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .loc {
    width: 15px;
    height: 15px;
  }

  .footer_2 > h2 {
    margin-left: 0;
  }
}
@media screen and (max-width: 540px) {
  .Footer_head {
    flex-direction: column;
    align-items: center;
  }

  .footer_1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_2,
  .footer_3,
  .footer_4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .head_1,
  .head2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .loc {
    width: 15px;
    height: 15px;
  }

  .footer_2 > h2 {
    margin-left: 0;
  }
}
@media screen and (max-width: 575px) {
  .Footer_head {
    flex-direction: column;
    align-items: center;
  }

  .footer_1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_2,
  .footer_3,
  .footer_4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .head_1,
  .head2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .loc {
    width: 15px;
    height: 15px;
  }

  .footer_2 > h2 {
    margin-left: 0;
  }
}
@media screen and (max-width: 734px) {
  .Footer_head {
    flex-direction: column;
    align-items: center;
  }

  .footer_1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_2,
  .footer_3,
  .footer_4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .head_1,
  .head2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .loc {
    width: 15px;
    height: 15px;
  }

  .footer_2 > h2 {
    margin-left: 0;
  }
}
@media screen and (max-width: 1000px) {
  .Footer_head {
    flex-direction: column;
    align-items: center;
  }

  .footer_1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_2,
  .footer_3,
  .footer_4 {
    text-align: center;
    margin-bottom: 20px;
  }

  .head_1,
  .head2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .loc {
    width: 15px;
    height: 15px;
  }

  .footer_2 > h2 {
    margin-left: 0;
  }
  .footer_1 > img {
    max-width: 109px;
  }
}
