.content_space{
    display: flex;
    height: 100vh;
}
.privacy_head{
    display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: black;
      padding-left: 18px;
      padding-top: 40px;
      width: 15%;
      max-height: 100%;
  }
  .policy_h{
    overflow: auto;
    height: 100vh;
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 21px;
    background-color: whitesmoke;
    padding-left: 30px;
  }
  @media (min-width:700px) and (max-width:900px){
    .privacy_head{
      width: 28%;
    }
  }
  @media (min-width:900px) and (max-width:1000px){
    .privacy_head{
      width:28%;
    }
  }