.TOheader {
  display: flex;
  justify-content: space-between;
  /* position: fixed;
  z-index: 1; */
  width: 100%;
  background-color: #ff992c;
  color: white;
  padding: 40px;

}
.headbar {
  display: flex;
  flex-direction: column;
}
.bardown {
  display: flex;
  gap: 20px;
}
.mainer {
  display: flex;
  flex-direction: column;
}
.editprf {
  border: 1px solid white;
  padding: 10px;
}
.top-head{
    height: 100vh;
    overflow: hidden;
}
@media screen and (max-width:640px) {
  .editprf{
    font-size: 10px;
    padding: 5px;
  }
  .headbar>h3{
    font-size: 10px;
  }
  .bardown>p{
    font-size:10px;
  }
  .TOheader{
    background-color:#ff992c ;
    width: 100%;
    padding: 20px;
    gap: 17px;
  }
}