.del{
    padding-left: 30px;
    
   
    gap: 20px;
    overflow: auto;
    height: 100vh;
    width: 89%;
    display: flex;
    flex-direction: column; 
  
    
}
.delivery_head > h2{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}
.dh-p{
    font-size: 20px;
}
.delivery_head{
    font-size: 20px;
}
.delivery_head2>h3{
    font-size: 20px;
    font-weight:600 ;
}
.delivery_head3>h3{
    font-size: 20px;
    font-weight:600 ;
}
.delivery_4>h3{
    font-size: 20px;
    font-weight:600 ;
}
.delivery_5>h3{
    font-size: 20px;
    font-weight:600 ;
}
.line_row1>p{
    font-size: medium;
    font-weight: 500;
}
.line_row2>p{
    font-size: medium;
    font-weight: 500;
}
.line_by_line{
    display: flex;
    flex-direction: column;
}   
.line_row1{
    padding-top: 5px;
}