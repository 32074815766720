@import url('https://fonts.googleapis.com/css2?family=Bellefair&family=Edu+VIC+WA+NT+Beginner:wght@400..700&family=Hubballi&family=PT+Sans&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Stint+Ultra+Expanded&family=Zilla+Slab+Highlight:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Meera+Inimai&family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&family=Rhodium+Libre&family=Sometype+Mono:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Meera+Inimai&family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&family=Rhodium+Libre&family=Sometype+Mono:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@400..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Meera+Inimai&family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&family=Rhodium+Libre&family=Sometype+Mono:ital,wght@0,400..700;1,400..700&display=swap');

* {
  font-family: "Hubballi", sans-serif;
  font-family: "Jost", sans-serif;
  /* font-family: "Rhodium Libre", serif; */
  /* font-family: "Edu TAS Beginner", cursive; */
  font-family: "Jost", sans-serif;
  font-weight: 300;
}

.con-out-bh {
  padding: 63px 0px 0px 0px;
}

.logo_1 {
  height: 150px;
  width: 180px;
  cursor: pointer;
}

input:focus-visible {
  outline: 0;
}

.q2h_logo {
  display: flex;
}

.content-top {
  padding-top: 50px;
}

.rotated_square_card {
  color: #3498db;
  transform: rotate(45deg);
  width: 100px;
  height: 100px;
}

.box {
  width: 100px;
  height: 40px;
  margin-top: 50px;
  background-color: orange;
  border: 1px solid orange;
  display: flex;
  /* transform: rotate(45deg); */
}
.dag{
  display: flex;
  justify-content: flex-end;
}
.content {
  display: flex;
}

.content_txt {
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
}

.download {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dw-con_1{
 padding-left: 27px;
}
.ps1 {
  width: 83%;
  /* height: 50px; */
  align-items: center;

}

.io1 {
  width: 125px;
  height: 55px;
  align-items: center;
}

.images_pa {
  display: flex;
  align-items: center;
  justify-content: center;
}

.span_txt {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}

.align_2 {
  display: flex;
}

.input_des {
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 540px;
}
.input_des_3{
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 540px;
}
.signup_align {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}
.edit{
  color:orange;
  cursor: pointer;
}
.alignn_3 {
  width: 67%;
  display: flex;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
}
.bug{
  display: flex;
  flex-direction: column;
 
}
.btnlogin {
  border: none;
  background-color: #f48407;
  color: #ffffff;
  padding: 10px;
  width: 540px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btnlogin:hover {
  background-color: #e07206;
}

.btn_islogin {
  border: none;
  background-color: #ffff;
  color: #f48407;
  font-weight: bold;
}

.li {
  display: flex;
  align-items: center;
  border: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  font-size: small;
  background: none;
  padding: 0px;
  padding-right: 10px;
  color: gray;
  width: 70%;
  justify-content: end;
}
::placeholder {
  color: #d3d3d3;
  opacity: 1;
}
.loci {
  width: 15px;
  color: gray;
  margin-right: 5px;
}

.frm {
  font-weight: 300;
  color: #282c3f;
  width: 250%;
  height: 58px !important;
  line-height: 28px !important;
  padding-top: 0 !important;
  box-sizing: border-box;
  font-size: 13px;
  padding-top: 20px;
  padding-left: 10px;
  border: none;
  border-right: none;
}

.submit {
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  width: 17%;
  height: 58px !important;
  line-height: 28px !important;
  background-color: #f48407;
  font-size: 16px !important;
  font-weight: 500;
  color: white;
  border: none;
  padding-top: 0 !important;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;


  &:hover {
    background-color: #e56e00;
    color: #ffffff;
  }
}


.btn_login {
  background-color: #ffffff;
  color: #f48407;
  border: none;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  width: 41%;
  height: 40px;
  padding: 3px;
  margin: 5px;
  font-weight: 600;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;


  &:hover {
    background-color: #f48407;
    color: #ffffff;
    cursor: pointer;
  }
}

.btn_login_1 {
  background-color: #f48407;
  color:#ffffff ;
  border: none;
  box-shadow: 0px 0px 10px 0px #e7e7e7;
  width: 41%;
  /* height: 40px; */
  padding: 10px;
  margin: 5px;
  font-weight: 400;
  /* font-weight: bold; */
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;


  &:hover {
    background-color: #ffffff;
    color: #f48407;
    cursor: pointer;
  }
}
.siggg{
  color: #fff;
}
.login_main {
  display: flex;
  flex-direction: column;
  /* padding-top: 10px; */
}

.q2h_logo {
  padding-bottom: 30px;
  justify-content: center;
  position: relative;
}

.under-text {
  color: #707070;
}

.otp-box {
  width: 30px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid black;
}

.otp-input {
  display: flex;

  gap: 10px;
}

.otpcolor {
  color: orange;
  padding-top: 10px;
}

.btn_11 {
  padding: 10px;
  cursor: pointer;
  width: 200px;
  height: 50px;
  align-items: center;
  background-color: orange;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: 400;
  transition: background-color 0.3s ease, color 0.3s ease;


  &:hover {
    background-color: darkorange;
    color: #ffffff;
  }
}

.btn_12 {
  padding: 10px;
  cursor: pointer;
  width: 200px;
  height: 50px;
  align-items: center;
  background-color: orange;
  border: none;
  color: white;
  border-radius: 5px;
  font-weight: 400;
  margin: 10px;

}

.btn_11.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.dw-con {
  padding-right: 20px;
  font-weight: 400;
  margin: 0;
}

.sl-r-bx {
  width: 340px;
  height: 340px;
  transform: skew(175deg);
  border-radius: 10px;
  rotate: -6deg;
  margin-bottom: 60px;
}

.r-img-bg {
  width: 100%;
  height: 100%;
  object-position: left;
  border-radius: 15px;
  object-fit: cover;
}

.sl-2-bg {
  width: 260px;
  height: 300px;
  transform: skew(180deg);
  border-radius: 15px;
  position: absolute;
  background-color: #f48407;
  bottom: -30px;
  left: -26px;
  z-index: -1;
}

.or-bx {
  background-color: #ff8603;
  width: 60px;
  height: 70px;
  position: absolute;
  top: -30px;
  right: 10px;
  border-radius: 3px;
  rotate: -6deg;
  transform: skew(175deg);
}

.or-bx02 {
  background-color: #ff8603;
  width: 60px;
  height: 70px;
  position: absolute;
  bottom: 0px;
  left: -1px;
  border-radius: 3px;
  rotate: -6deg;
  transform: skew(175deg);
  z-index: -1;
}

.li-p {
  font-size: 12px;
  margin: 0;
  color: #d3d3d3;
  width: 63px;
}

.li-im {
  width: 20px;
}

.loci-p {
  font-size: 12px;
  color: #d3d3d3;
}

.align_2 {
  margin-bottom: 15px;
}

/* animation */

.q2h_logo {
  position: relative;
  left: -100%;
  opacity: 0;
  animation: slideAnimation 1.5s ease-out forwards;
}
.better_1{
  font-weight: 400;
}
.better{
  font-weight:400 ;
}
.gpap{
  padding-left: 0 !important;
}
.login_24 {
  display: flex;
  margin-top: 24px;
}
.playstore-1{
  width: 118px;
}

@keyframes slideAnimation {
  to {
    left: 0;
    opacity: 1;
  }
}

.download-sect-animation {
  position: relative;
  left: -100%;
  opacity: 0;
  animation: slideAnimation 1.5s ease-out forwards;
  text-align: end;
}

.animated-text {
  position: relative;
  left: -100%;
  opacity: 0;
  animation: slideAnimation 1.5s ease-out forwards;
}

@keyframes slideAnimation {
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideAnimation {
  to {
    left: 0;
    opacity: 1;
  }
}



@media (max-width: 800) {
  .align_2::before {
    background-position: center center;
  }
}

@media (max-width: 590px) {
  .mobile_view {
    display: none;
  }

  .main_align {
    padding-left: 30px;
    padding-right: 30px;
  }

  .growth {
    font-size: medium;
  }
}

@media (max-width: 1024px) {
  .mobile_view {
    display: none;
  }

  .main_align {
    padding-left: 30px;
    padding-right: 30px;
  }

  .growth {
    font-size: medium;
  }

  .align_2 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .con-out-bh {
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .sl-r-bx {
    margin-top: 50px;
  }

  

  .login_24 {
    display: flex;
    justify-content: center;

  }

  .alignn_3 {
    width: 73%;
  }
}
@media(min-width:900px) and (max-width:980px) {
  .input_des_3 {
    width: 535px;
  }

  .btnlogin {
    width: 535px;
  }
  .btn_login_1{
    width: 38%;
  }
}

@media(min-width:990px) and (max-width:1000px) {
  .input_des_3 {
    width: 450px;
  }

  .btnlogin {
    width: 450px;
  }
  .input_des {
    width: 450px;
  }
}


@media(min-width:1000px) and (max-width:1350px) {
  .input_des_3 {
    width: 430px;
  }

  .btnlogin {
    width: 467px;
  }
  .input_des {
    width: 430px;
  }

}
@media(min-width:1200px) and (max-width:1350px) {
  .input_des_3 {
    width: 467px;
  }

  .btnlogin {
    width: 467px;
  }
  .input_des {
    width: 467px;
  }
}
@media(max-width:1200px){
  
}
@media(min-width:1030px) and (max-width:1200px) {
  .input_des {
    width: 431px;
  }

  .btnlogin {
    width: 431px;
  }

}
@media(max-width:330px){
  .dw-con{
     
  
      display: flex;
      flex-direction: column;
      padding-right: 54px;
      justify-content: center;
 
  }
  .better_1{
    padding-right: 30px;
  }
  .dag{
    padding-left: 33px;
  }
}
@media(min-width:330px)and (max-width:400px){
  .dw-con{
     
  
    display: flex;
    flex-direction: column;
   align-items: center;
    justify-content: center;

}
.better_1{
  padding-right: 30px;
}
/* 
 */
 
}
@media(max-width:300px){
   .dw-con{
    padding-right: 37px;
   }
}
@media(min-width:1000px) and (max-width:1024px) {
  .input_des_3 {
    width: 431px;
  }

  .btnlogin {
    width: 431px;
  }

  .btn_login_1 {
    width: 41%;
  }

  .login_24 {
    display: flex;

  }
}
@media (min-width: 591px) and (max-width: 100px) {
  .mobile_view {
    display: none;
  }

  .main_align {
    padding-left: 30px;
    padding-right: 30px;
  }

  .growth {
    font-size: medium;
  }
}



@media (max-width: 500px) {
  .or-bx02 {
    position: relative;
  }

  .sl-r-bx {
    width: 260px;
    height: 300px;
  }

  .modal_content_11 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btnlogin {
    width: 379px;
  }

  .input_des_3 {
    width: 379px;
  }
  .input_des {
    width: 379px;
  }
}

@media (max-width: 991px) {
  .content_txt {
    text-align: center;
  }

  .span_txt {
    text-align: center;
  }

  .download {
    flex-direction: column;
    text-align: center;
  }

  .signup_align {
    display: flex;
    align-items: center;
  }
  .btn_login_1 {
    width: 38%;
  }
}
@media (max-width: 600px) {
  .loci-p {
    display: none;
  }

  .li {
   
    padding-right: 0px;
  }

  .frm {
    width: 182%;
    font-size: 14px;
  }

  .alignn_3 {
    width: 70%;
  }

  .submit {
    width: 30%;
  }

  .btnlogin {
    width: 502px;
  }

  .input_des_3 {
    width: 500px;
  }
  .input_des {
    width: 500px;
  }

  .btn_login_1 {
    width: 53%;
  }
}

@media (min-width:720px) {}

@media (max-width:500px) {
  .signup_align {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

  }
}



@media (max-width:430px) {
  .btnlogin {
    width: 392px;
  }

  .input_des_3 {
    width: 392px;
  }
  .input_des {
    width: 392px;
  }
  .footer_1 > img {
    max-width: 103px;
}
.gpap{
  flex-direction: column;
margin-left: 100px;
}
.welcome-text {
  font-size: 26px;
  line-height: 41px;
  font-weight: 200;
}
}

@media (max-width:395px) {
  .btnlogin {
    width: 356px;
  }

  .input_des_3 {
    width: 356px;
  }
  .input_des {
    width: 356px;
  }
  .better{
    font-weight: 400;
  }
  .gpap{
    flex-direction: column;
  margin-left: 80px;
  }
  
}

@media (max-width:375px) {
  .btnlogin {
    width: 349px;
  }

  .input_des_3 {
    width: 349px;
  }
  .input_des {
    width: 349px;
  }
  .dag{
    display: flex;
    justify-content: center;
    margin-right: 29px;
  }
}

@media (max-width:340px) {
  .input_des_3 {
    width: 250px;
  }

  .btnlogin {
    width: 250px;
  }
  .input_des {
    width: 250px;
  }
  .gpap{
    
  margin-left: 22px;
  }
  
}

@media (min-width:1025px) {
  .login_24 {
    display: flex;
    justify-content: center;
    width: 86%;
  }

  .btn_login_1 {
    width: 50%;
  }

  .alignn_3 {
    width: 68%;
  }
}
@media (max-width:350px){
  .dag{
       display: flex;
       flex-direction: column;
       align-items: center ;
      
  }
}
@media (max-width:310px){
  .gpap{
    
    margin-left: 2px;
    }
}

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");
@import url(https://fonts.googleapis.com/css?family=Open+Sans);


.download-temp-ul {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  /* position: absolute; */
  top: 50%;
  /* transform: translateY(-50%); */
}

.download-temp-li {
  display: inline-block;
  margin: 10px;
}

.download {
  width: 160px;
  height: 45px;
  background: black;
  float: left;
  border-radius: 5px;
  position: relative;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  transition: all .4s ease-in-out;
}

.download>.fa {
  color: #fff;
  position: absolute;
  top: 50%;
  font-size: 30px;
  left: 15px;
  transform: translateY(-50%);
}

.df,
.dfn {
  position: absolute;
  font-family: "Hubballi", sans-serif;
  left: 55px;
}

.df {
  top: 2px;
  font-size: .68em;
}

.dfn {
  font-weight: bold;
  top: 16px;
  font-size: 20px;
}

.download:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.welcome-text {
  font-size: 40px;
  line-height: 43px;
  font-weight: 200;
}

/* button animation */


.btn_login_1 {
  font-size: 20px;
  /* color: #bdbdbd; */
  border: none;
  /* font-weight: bold; */
  position: relative;
}

.btn_login_1 svg {
  /* top: 4px; */
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  margin-left: -40px;
  opacity: 0;
  position: unset;
  transform: translateX(75%) scaleX(0.1);
  width: 40px;
  /* height: 40px; */
  transform-origin: center;
}

.btn_login_1:hover {
  cursor: pointer;
}

.btn_login_1:hover svg {
  opacity: 1;
  transform: translateX(30px) scaleX(1);
}

.btn_login_1:hover span {
  transform: translateX(-30px);
}

.radius span {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  color: #fff;
}

.radius {
  background-color: transparent;
}

.btn_login_1:hover .radius span {
  color: #f48407;
}







.btnlogin {
  font-size: 20px;
  /* color: #bdbdbd; */
  border: none;
  /* font-weight: bold; */
  position: relative;
}

.btnlogin svg {
  /* top: 4px; */
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  margin-left: -40px;
  opacity: 0;
  position: unset;
  transform: translateX(75%) scaleX(0.1);
  width: 40px;
  /* height: 40px; */
  transform-origin: center;
}

.btnlogin:hover {
  cursor: pointer;
}

.btnlogin:hover svg {
  opacity: 1;
  transform: translateX(30px) scaleX(1);
}

.btnlogin:hover span {
  transform: translateX(-30px);
}

.radius span {
  display: inline-block;
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  color: #fff
}

.radius {
  background-color: transparent;
}

.btnlogin:hover .radius span {
  color: #fff;
}

.btnlogin .radius span {
  color: #fff !important;
}