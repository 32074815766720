.ref{
  padding-left: 30px;
    width: 89%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    height: 100vh;
}
.refund_1 > h2 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.refund{
  font-size: 20px;
}