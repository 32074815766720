.ter{
    padding-left: 30px;
    overflow: auto;
    height: 100vh;
    width: 89%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.terms > h2{
    display: flex;
    justify-content: center;
}