.sidehead {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 30px;
  background-color: whitesmoke;
  height: 100%;
  z-index: 1000;
}
.sidehead p {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.fav1 {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #474747;
}
.sideside_head {
  background-color: whitesmoke;
}

.navbar-toggler {
  z-index: 1000;
  position: absolute;
  background-color: white;
}
.fav1:hover {
  color: #bdbcbc;
}
@media screen and (max-width: 700px) {
  .sidebar {
    display: none;
  }

  .navbar-toggler {
    display: block;
  }
  .sidebar.show {
    display: block;
  }
  .sidehead {
    position: relative;
    margin-top: 29px;
  }
  .fav1 {
    font-size: small;
  }
  .sideside_head {
    background-color: white;
    position: absolute;
  }
}

@media screen and (min-width: 700px) {
  .navbar-toggler {
    display: none;
  }
  .sideside_head {
    background-color: whitesmoke;
  }
}
@media screen and (max-width: 640px) {
  .sideside_head {
    background-color: white;
    position: absolute;
  }
}
