
.privacy_head {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: black;

    padding: 50px;
    width: 11%;
    max-height: 100%;




}

.privacy_head>p {
    color: white;
    font-size: medium;
    cursor: pointer;
    font-weight: 500;
}

.image_c {

    display: flex;

}

.p_log {
    width: 80px;
    height: 65px;
}
.p_color{
    color: white;
    cursor: pointer;
}
.pr-hd{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}


@media(max-width:700px) {
    .privacy_head {
        width: 100% !important;
        padding: 10px 15px;
    }

    .content_space {
        flex-direction: column;

    }

    .pr-hd {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #fff;
        padding: 0px 20px;
    }

    .policy_h {
        width: 100% !important;
    }
    .ref{
        width: 100%;
    }
    .del{
        width: 100%;
    }
    .ter{
        width: 100%;
    }
    .image_c {
        display: flex;
        justify-content: center;
    }
}
body{
    font-family: "delivery" !important;
}